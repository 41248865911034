
require ('./src/js/tabler.esm');
var $ = require("jquery");
window.jQuery = $;
window.$ = $;
document.addEventListener("DOMContentLoaded", function () {4
  window.addEventListener('load', function () {
      var loader = document.getElementById('main-wrap-loader');
      loader.style.transition = 'opacity 0.5s';
      loader.style.opacity = '0';
      setTimeout(function () {
          loader.style.display = 'none';
      }, 500);
  });
});
$(document).ready(function($){
  console.log('loading complete')
});
